<template>
  <div id="canvas"></div>
</template>

<script>
import { Renderer, Camera, Transform, Program, Mesh, TextureLoader, Plane } from "ogl";

export default {
  name: "OglBackground",
  data() {
    return {
      logo: require("@/assets/logo/bureau-luthi-logo-main-full-black.svg"),
      pos: { x: 0, y: 0 },
      mouse: { x: 0, y: 0 },
      force: { x: 0, y: 0 },
      camera: { fov: 35, z: 6 },
      stiffness: 0.06,
      scrollOffset: 0,
      breakpoint: 992,
      // images: [
      //   {
      //     id: 0,
      //     path: require("@/assets/images/EFH_Nuolen-6@2x.jpg"),
      //     x: -2,
      //     y: 1,
      //     z: 0,
      //   },
      //   {
      //     id: 0,
      //     path: require("@/assets/images/007 Ace _ Tate by Conny Mirbach@2x.jpg"),
      //     x: -1.2,
      //     y: 1.3,
      //     z: -0.3,
      //   },
      // ],
      images: [
        {
          id: 0,
          path: require("@/assets/images/luthi-1.jpg"),
          desktop: { x: -2.2, y: 1, z: -0.8 },
          mobile: { x: -0.75, y: 2.1, z: -1.2 },
          scale: 1.3,
        },
        {
          id: 1,
          path: require("@/assets/images/luthi-2.jpg"),
          desktop: { x: -0.85, y: 1, z: 0.3 },
          mobile: { x: 0.3, y: 1.1, z: 0.5 },
          scale: 1,
        },
        {
          id: 2,
          path: require("@/assets/images/luthi-3.jpg"),
          desktop: { x: 0, y: 1.3, z: -0.1 },
          mobile: { x: 1.2, y: 0.9, z: 0 },
          scale: 1,
        },
        {
          id: 3,
          path: require("@/assets/images/luthi-4.jpg"),
          desktop: { x: 1.05, y: 0.9, z: -1 },
          mobile: { x: -1.3, y: -1.2, z: -1.5 },
          scale: 1.3,
        },
        {
          id: 4,
          path: require("@/assets/images/luthi-5.jpg"),
          desktop: { x: 1.6, y: 0.8, z: 0.8 },
          mobile: { x: -1.15, y: 0.5, z: 0.3 },
          scale: 0.9,
        },
        {
          id: 5,
          path: require("@/assets/images/luthi-6.jpg"),
          desktop: { x: -1.8, y: -0.5, z: 0.5 },
          mobile: { x: 80, y: -2, z: 1 },
          scale: 1,
        },
        {
          id: 6,
          path: require("@/assets/images/luthi-7.jpg"),
          desktop: { x: -0.75, y: -0.8, z: 1 },
          mobile: { x: 0.7, y: 2.3, z: 0.1 },
          scale: 0.9,
        },
        {
          id: 7,
          path: require("@/assets/images/luthi-8.jpg"),
          desktop: { x: 0, y: -0.8, z: -1 },
          mobile: { x: -0.1, y: -0.75, z: -0.3 },
          scale: 1.2,
        },
        {
          id: 8,
          path: require("@/assets/images/luthi-9.jpg"),
          desktop: { x: 0.85, y: -0.8, z: 0.3 },
          mobile: { x: 0.85, y: -0.8, z: 0.7 },
          scale: 1,
        },
        {
          id: 9,
          path: require("@/assets/images/luthi-10.jpg"),
          desktop: { x: 2, y: -0.4, z: -0.4 },
          mobile: { x: 0, y: -2.1, z: -0.75 },
          scale: 1.1,
        },
      ],
    };
  },
  methods: {
    getHeight() {
      return document.getElementById("header").offsetHeight;
    },
    handleScroll() {
      this.scrollOffset = window.scrollY;
    },
    setMouse(e) {
      // camera.position.y = e.clientY / window.innerHeight / 2;
      let centerWidth = window.innerWidth - window.innerWidth / 2;
      let centerHeight = window.innerHeight - window.innerHeight / 2;
      let clientX = e.clientX;
      let clientY = e.clientY;
      let factor = -5000;

      this.mouse.x = this.ease((clientX - centerWidth) / factor);
      this.mouse.y = this.ease((clientY - centerHeight) / -factor);
    },
    ease(t) {
      return t * (2 - t);
    },
    oglSetup() {
      const vertex = /* glsl */ `
                attribute vec2 uv;
                attribute vec3 position;
                attribute vec3 normal;
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;
                uniform mat3 normalMatrix;
                varying vec2 vUv;
                varying vec3 vNormal;
                void main() {
                    vUv = uv;
                    vNormal = normalize(normalMatrix * normal);
                    
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `;

      const fragment = /* glsl */ `
                precision highp float;
                uniform sampler2D tMap;
                varying vec2 vUv;
                varying vec3 vNormal;
                void main() {
                    vec3 normal = normalize(vNormal);
                    vec3 tex = texture2D(tMap, vUv).rgb;
                    
                    vec3 light = normalize(vec3(0.5, 1.0, -0.3));
                    float shading = dot(normal, light) * 0.15;
                    
                    gl_FragColor.rgb = tex + shading;
                    gl_FragColor.a = 1.0;
                }
            `;

      const renderer = new Renderer({ dpr: 2 });
      const gl = renderer.gl;
      document.getElementById("canvas").appendChild(gl.canvas);
      gl.clearColor(1, 1, 1, 1);

      const camera = new Camera(gl, { fov: this.camera.fov });
      // camera.position.set(0, 0, 5);
      camera.lookAt([0, 0, 0]);

      const scene = new Transform();
      // const planeGeometry = new Plane(gl, { width: 0.75 });

      // let textures = [];
      // let programs = [];

      for (let image of this.images) {
        const planeGeometry = new Plane(gl, {
          width: 0.8 * image.scale,
          height: image.scale,
        });

        let texture = TextureLoader.load(gl, {
          src: image.path,
        });
        // textures.push(texture);

        let program = new Program(gl, {
          vertex,
          fragment,
          uniforms: {
            tMap: { value: texture },
          },
        });
        // programs.push(program);

        let plane = new Mesh(gl, {
          geometry: planeGeometry,
          program: program,
        });
        plane.position.set(image.desktop.x, image.desktop.y, image.desktop.z);

        plane.setParent(scene);
      }
      // let p = 0;

      let resize = () => {
        renderer.setSize(window.innerWidth, this.getHeight());
        camera.perspective({ aspect: gl.canvas.width / gl.canvas.height });

        if (gl.canvas.width > this.breakpoint) {
          this.camera.z = 6;
          this.camera.fov = 45;
        } else {
          this.camera.z = 8;
          this.camera.fov = 35;
        }
      };
      window.addEventListener("resize", resize, false);
      resize();

      // Animation variables
      let x = 0;

      let update = () => {
        // Increment animation value with a maximum of 1
        x = Math.min(x + 0.01, 1);

        // Apply spring physics
        this.force.x = (this.mouse.x - camera.position.x) * this.stiffness;
        this.force.y = (this.mouse.y - camera.position.y) * this.stiffness;
        this.pos.x += this.force.x;
        this.pos.y += this.force.y;

        // Update camera position
        camera.position.x = this.pos.x;
        camera.position.y = this.pos.y;
        camera.position.z = 2 * (1 - this.ease(x)) + this.camera.z - this.scrollOffset / 100;
        camera.fov = this.camera.fov;

        // Update positions of all images based on screen size and scroll
        for (let i = 0; i < scene.children.length; i++) {
          const position =
            gl.canvas.width > this.breakpoint ? this.images[i].desktop : this.images[i].mobile;

          scene.children[i].position.x =
            position.x + (this.scrollOffset / 2000) * Math.sign(position.x);
          scene.children[i].position.y =
            position.y + (this.scrollOffset / 1000) * Math.sign(position.y);
          scene.children[i].position.z = position.z;
        }

        requestAnimationFrame(update);
        renderer.render({ scene, camera });
      };
      requestAnimationFrame(update);

      setTimeout(() => {
        this.WEBGLloaded = true;
      }, 200);
    },
  },
  mounted() {
    this.oglSetup();
    document.addEventListener("mousemove", this.setMouse);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.setMouse);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
#canvas {
  position: absolute;
  top: 0;
}
</style>
